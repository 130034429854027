import { useContext, useRef, useState,useEffect } from "react";

import AuboisNewsIcon from "../../public/images/ico/ico-news.svg";
import { Carousel } from "react-responsive-carousel";
import { Context } from "../../utils/store";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import Link from "next/link";
import extractData from "../../utils/extractVariablesFromTemplates";
import getPubs from "../../utils/pubs/rightBlock";
import rightBlock from "../../utils/pubs/rightBlock";
import Image from 'next/image'
export default function AuboisNews() {
    const carousel = useRef(null);
    const [activeDot, setActiveDot] = useState(0);
    const [pubs,setPubs]=useState(null)
    let context = useContext(Context);
    let auboisVousInforment = context?.auboisVousInforment;
    
    if (auboisVousInforment) {
      auboisVousInforment = auboisVousInforment.items.slice(0,5)
    }

    const getPubsRight = async() =>{
        let selectedPubs =await rightBlock(context)
        
        
        setPubs(selectedPubs);
    }

    useEffect(()=>{
        getPubsRight();
    },[])
    return (
        <Context.Provider
            value={{
                ...context,
            }}
        >
            <div className="an-container rounded overflow-hidden">
                <div className="an-header">
                    <AuboisNewsIcon className="an-icon" />
                    <p className="an-header-title">Les Aubois vous informent</p>
                </div>
                <div className="overflow-hidden">
                    <Carousel
                        showStatus={false}
                        autoPlay={true}
                        interval={8000}
                        infiniteLoop={true}
                        emulateTouch={true}
                        dynamicHeight={700}
                        swipeable={true}
                        showThumbs={false}
                        
                        renderIndicator={(
                            onClickHandler,
                            isSelected,
                            index
                        ) => {
                            return (
                                <span
                                    key={index}
                                    onClick={onClickHandler}
                                    onKeyDown={onClickHandler}
                                    className={`${
                                        isSelected
                                            ? "text-primary-orange bg-primary-orange"
                                            : ""
                                    }
            w-10 h-10 rounded-full border-1 border-primary-orange inline-block mr-8 
            `}
                                ></span>
                            );
                        }}
                        className="an-content-carousel"
                    >
                        {auboisVousInforment &&
                            auboisVousInforment.map((e, i) => {
                              let {actus_aubois_fichier_img, actus_aubois_nom, video_date_timestamp} = extractData(e.template_variables, "aubois");
                                return (
                                    <div key={i} className="an-carousel-elem min-h-600 max-h-600">
                                        {actus_aubois_fichier_img ? 
                                            <Image
                                                className="an-img-elem-carousel"
                                                src={actus_aubois_fichier_img}       
                                                alt="Image Aubois vous Informent"             
                                                layout="responsive"
                                                width={334}  
                                                height={400}                      
                                            />                                        
                                        : null}

                                        <h3 className="normal-case text-18 font-nsjp font-bold text-left aubois-info-title">
                                            {e.pagetitle}
                                        </h3>
                                        <p className="text-date text-left mb-5 mt-5 text-sm text-primary-grey">
                                            {`Publié par ${actus_aubois_nom} le ${video_date_timestamp}`}
                                        </p>
                                    </div>
                                );
                            })}
                    </Carousel>
                </div>
                <div className="an-footer">
                    <p className="mt-5 mb-5 text-xs">
                        Vous avez quelque chose à nous raconter ?
                    </p>
                    <Link href="/contact">
                        <button>
                            <p>Je souhaite envoyer une info</p>
                        </button>
                    </Link>
                </div>
            </div>

            {pubs ? 
                pubs.map((pub,index)=>(
                    <a href={pub.lien} className="mt-10 cursor-pointer block" target="__blank" key={`pub-right-${index}`}>
                        <img className="w-full" src={pub.image} alt="Publicité"/>
                    </a>                
                ))

            :null }

        </Context.Provider>
    );
}
