import Moment from "react-moment"
import { capitalizeFirstLetter } from "../../utils/utilsFunctions";
import dynamic from 'next/dynamic'

export default function DateAndTopic({ date, topic, typearticle}) {
  const bigArticle = typearticle === "big" ? "-big-article" : '';
  return (
    <div className="dandt-container">
      <span className={`dandt-text-date${bigArticle}`}>Diffusion le {date} | <span className={`text-topic${bigArticle}`}>{capitalizeFirstLetter(topic ? topic : "Emission")}</span></span>
    </div>
  );
}
