import setGetData from "../data";


export default async function getPubs(type,isBlock=false,random=false,isMobile=false){
    return new Promise(async(resolve, reject) => {
        let pubs ={};
        let value;
        if(isBlock){
            value = await setGetData(
                process.env.SOURCE_API + "rest/2.0/pub/getPub.php",
                "POST",
                null,
            );
            value =value[type];
        } else {
            if(isMobile){
                value = await setGetData(
                    process.env.SOURCE_API + "rest/2.0/pub/get.php",
                    "POST",
                    [{name:'type',value:type}],
                );
            } else {
                value = await setGetData(
                    process.env.SOURCE_API + "rest/2.0/pub/get_"+type+".php",
                    "POST",
                    null,
                );
                value =value[type];
            }
            

        }
        if(random){
            let index= Math.floor(Math.random() * value.length)
            pubs = value[index]
        }
        else{
            pubs = value
        }
        resolve(pubs)

    });
}