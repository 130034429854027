import Article from "./Article";
import BigArticle from "./BigArticle";
import {Context} from "../../utils/store";
import EmptyStack from "./EmptyStack";
import LongArticle from "./LongArticle";
import TopicHeader from "../TopicHeader";
import {useContext} from "react";

export default function StackTopic({articles, type}) {
    const context = useContext(Context);
    let topic;
    switch(type) {
        case "chaine-ephemere":
        topic = context.ecPageName;
        break;

        case "cities":
        topic = context.cityName;
        break;

        case "searched":
        topic = context.keyWord;
        break;
    }

    return (
        articles.length ?
        type === "article" ?
        <Article data={articles} isAloneArticle={true} />
        :
        articles.map((e, i) => {
            if (i === 0) { // Premier élément
                return (       
                    <div key={i}>    
                    <TopicHeader type={type} topic={topic ? topic : e.parentName} />
                    <BigArticle data={e} />
                    </div>
                )
            }   
            else {
                return <LongArticle data={e} key={i} />
            }
        })
        :
        <EmptyStack type={type} topic={topic}/>
    )
}