import { useContext, useState } from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import { Context } from "../utils/store";

export default function SeeMoreButton({ onClickFunction }) {
    const context = useContext(Context);
    const [loadingState, setLoadingState] = useState(false);
    return (
        <Context.Provider
            value={{
                ...context,
                setLoadingState: setLoadingState,
            }}
        >
            <div
              className="w-full flex flex-row items-center justify-center"
            >
            <button
                onClick={onClickFunction}
                className="mt-32 mb-80 text-primary-white bg-blue-ephemeral-channel rounded-sm flex flex-row items-center justify-self-center justify-center py-4 hover:bg-dark-blue transition-all duration-200"
            >
                <p
                    className={`py-10 font-nsjp font-bold text-16 px-16 ${
                        loadingState ? "mr-8" : ""
                    }`}
                >
                    Voir plus d'articles
                </p>
                {loadingState && (
                    <CircularProgress
                        style={{
                            color: "white",
                        }}
                    />
                )}
            </button>
            </div>
        </Context.Provider>
    );
}
