import React from 'react';

export default function Container({children}) {
    return (
        <div className="bg-light-gray flex justify-center">
        <div className="container-subContainer">
        {children}
        </div>
        </div>
    )
}