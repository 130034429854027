import React, { useContext, useState } from "react";

import { CircularProgress } from '@material-ui/core';
import {Context} from "../../utils/store";
import Link from "next/link";
import fetchData from "../../utils/data";
import Image from 'next/image'

export default function EphemeralChannel() {
  const context = useContext(Context);
  const {ephemeralChannels} = context;
  return (
    <div className="ephem-ch-container">
      <h3>Découvrez nos chaînes éphémères</h3>
      <div className="overflow-y-auto w-full flex flex-wrap flew-row justify-between justify-items-center ephem-ch-list">
          {
          ephemeralChannels.items.map((e, i) => {
            let image = e.template_variables.find(e => {
              return e.name === "img_chaine"
            })
            return (
              <Link
              as={`/chaine-ephemere/${e.id}`}
                href={{
                  pathname: "/chaine-ephemere/[id_chaine]",
                  query: { pagename: e.alias }, 
                }}
                as={{ pathname: `/chaine-ephemere/${e.alias}` }}

                key={i}
              >
                <div className="ephem-ch-channel">
                  <div className="w-116 mr-10">
                    {
                      image ? 
                        <Image src={image.value} alt={`Image de la chaîne éphémère ${e.pagetitle} de Canal 32.`} width={116} height={66} layout="responsive"/>
                      : null
                    }

                  </div>
                  <p>{e.pagetitle}</p>
                </div>
              </Link>
            );
          })
        }
        </div>
    </div>
  );
}
