import { Context } from "../../utils/store";
import LongArticle from "../Articles/LongArticle";
import getMostViewedArticles from "../../utils/getMostViewedArticles";
import { useContext } from "react";
const LENGTH_OF_MOST_VIEWED_LIST = 3;

export default function MostViewed() {
    const context = useContext(Context);
    let medias = context?.medias;
    let mostViewedArticles = getMostViewedArticles(medias);
    mostViewedArticles = mostViewedArticles.slice(0, LENGTH_OF_MOST_VIEWED_LIST);
    return (
        <Context.Provider
            value={{
                ...context,
            }}
        >
            <h3 className="mostviewed-title">Les vidéos les plus vues</h3>
            {
                mostViewedArticles.map((e, i ) => {
                    return (
                        <LongArticle key={i} data={e} size={"little"}/>
                    )
                })
            }
        </Context.Provider>
    );
}
