import extractData from "./extractVariablesFromTemplates";

const getMostViewedArticles = (data) => {
    let res = data;
    if (data && data.items) res = data.items;
    res.sort((a, b) => {
        let extractedDataA, extractedDataB;
        if (a.template_variables && b.template_variables) {
            extractedDataA = extractData(a.template_variables, "article");
            extractedDataB = extractData(b.template_variables, "article");

            let compteurA = extractedDataA.video_compteur_vue;
            let compteurB = extractedDataB.video_compteur_vue;

            if (
                typeof compteurA === "string" &&
                typeof compteurB === "string" &&
                !isNaN(compteurA) &&
                !isNaN(compteurB)
            ) {
                compteurA = parseInt(compteurA);
                compteurB = parseInt(compteurB);
                
                if (compteurA > compteurB) {
                    return -1;
                } else if (compteurA < compteurB) {
                    return 1;
                } else {
                    return 0;
                }
            } else {
                return 0;
            }
        } else {
            return 0;
        }
    });
    return res;
};

export default getMostViewedArticles;