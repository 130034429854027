import Moment from "react-moment"
import { capitalizeFirstLetter } from "../../utils/utilsFunctions";

export default function DateAndTopicArticleAlone({ date, topic, pubPlaying, initCast, data }) {
  return (
    <div className="dandt-container-alone">
      <span className="dandt-text-date">Diffusion le {date} | <span className="text-topic">{capitalizeFirstLetter(topic ? topic : "Emission")}</span></span>
      {
        !pubPlaying 
        ? <div className="cast-icon" onClick={() => initCast(data)}>
            <google-cast-launcher className="text-primary-white"  />
          </div> : null
      }
    </div>
  );
}
