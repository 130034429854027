import Link from "next/link";

export default function LinkArticle({children, data}) {
    return (
        <Link
            as={`/${data.uri}`}
            href="/thematiques/[...uri]"
        >
            {children}
        </Link>
    )
}