import { useContext, useEffect, useState } from "react";

import { Context } from "../../utils/store";
import IcoAround from "../../public/images/ico/ico-around.svg";

export default function Cities(props) {
  let context = useContext(Context)
  const { cities } = context;
  cities.items.sort((e1, e2) => {
    let p1 = e1.pagetitle;
    let p2 = e2.pagetitle;

    if (p1 > p2) {
      return 1;
    } else if (p1 < p2) {
      return -1;
    } else {
      return 0;
    }
  });
  
  return (
    <Context.Provider
      value={{
        ...context,
      }}
    >
      <div className="h-685">
        <div className="h-full flex flex-col">
          <h1 className="font-nsjp font-bold text-14 text-primary-dark p-16 border-1 border-b-0 border-gray">
            Rechercher les reportages par ville : 
          </h1>
          <div className="px-16 pt-16 overflow-auto pb-32 bg-primary-white border-1 border-gray flex-auto">
              {cities.items.map((e, i) => {
                let isActiveCity = props.activeCity === e.pagetitle;
                let styleButton = isActiveCity ? "bg-dark-blue":"bg-light-gray";
                let styleText = isActiveCity ? "text-primary-white":"text-primary-dark";

                return (
                  <button
                    onClick={() => {
                      props.handleCity(e)
                    }}
                    key={i}
                    className={`${styleButton} w-full py-16 rounded mb-16 flex flex-row items-center cities-item`}
                  >
                    <IcoAround className="w-24 mx-16" />
                    <a
                      id={e.id}
                      className={`${styleText} font-nsjp font-bold text-12 text-primary-dark`}
                    >
                      {e.pagetitle}
                    </a>
                  </button>
                );
              })}
          </div>          
        </div>

      </div>
    </Context.Provider>
  );
}
