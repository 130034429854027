import { useEffect, useState } from "react";

import BigArticle from "./BigArticle";
import Little from "./LittleArticle";
import LittleArticle from "./LittleArticle";
import LongArticleHome from "./BigArticleHome";
import ShowsAndNews from "../Shows&News";

export default function FirstStackArticles({ data, stackID }) {

  const [isPhone, setIsPhone] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", resize);
    function resize() {
      window.innerWidth > 319 && window.innerWidth < 481 ? setIsPhone(true) : setIsPhone(false)
      window.innerWidth > 599 && window.innerWidth < 1024 ? setIsTablet(true) : setIsTablet(false)
    } 
    resize();
    
    return () => window.removeEventListener("resize", resize)
    }, []);

    

  return (
      <div>
        {
          stackID === 0
          ?
          <>
          <BigArticle data={data[0]}/>
         { isTablet && <ShowsAndNews containerStyle="mb-32"/> }
          </>
          :
          isPhone ?
          <LittleArticle data={data[0]}/>
          :
          <LongArticleHome data={data[0]} />
        }
        <div className="fs-container-all-little-articles">
        {
          data.map((e, i) => {
            if (i !== 0) {
              return (
                <LittleArticle key={i} data={e}/>
              )
            }
          })
        }
        </div>
      </div>
  );
}
