import FacebookIcon from "../../public/images/ico/ico-facebook.svg";
import GoogleMapReact from "google-map-react";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import TwitterIcon from "../../public/images/ico/ico-twitter.svg";
export default function Contact({}) {
  let center = {
    lat: 48.27669,
    lng: 4.066348,
  };
  let zoom = 15;

  const AnyReactComponent = ({ text }) => (
    <div className="relative">
      <LocationOnIcon className="text-primary-red" fontSize="large"/>
      <p className="absolute bottom-0 right-0 top-0 font-nsjp font-bold text-primary-red text-center">{text}</p>
    </div>
  );

  return (
    <div className="bg-primary-white border-1 border-gray p-16 w-full">
      <div className="h-280 w-full mb-16">
        <GoogleMapReact
          bootstrapURLKeys={{ key: process.env.MAP_KEY }}
          defaultCenter={center}
          defaultZoom={zoom}
        >
          <AnyReactComponent lat={48.27669} lng={4.066348} text="Canal 32" />
        </GoogleMapReact>
      </div>
      <div>
        <div className="flex flex-row">
          <img src="images/logo-canal32.jpeg" className="w-24 h-24 mr-8" alt="Logo canal32"/>
          <p className="font-nsjp font-bold text-14 text-dark-blue">CANAL 32</p>
        </div>
        <p className="font-nsjp font-normal text-14 text-primary-dark mt-8">
          7 rue Raymond Aron
        </p>
        <p className="font-nsjp font-normal text-14 text-primary-dark mb-16">
          10120 Saint-André-les-Vergers
        </p>
        <p className="font-nsjp font-normal text-14 text-primary-dark">
          Tel : 03 25 72 32 32
        </p>
        <p className="font-nsjp font-normal text-14 text-primary-dark">
          Fax : 03 25 72 31 99
        </p>
        <div className="w-full min-h-1 bg-gray my-16" />
        <div>
          <div className="flex flew-row items-center">
            <p className="font-nsjp font-bold text-14 text-primary-dark mr-4">
              Retrouvez nous sur :
            </p>
            <a
              href="https://twitter.com/canal32?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor"
              target="_blank"
              rel="noopener"
            >
              <TwitterIcon />
            </a>
            <a href="https://fr-fr.facebook.com/canal32" target="_blank" rel="noopener">
              <FacebookIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
