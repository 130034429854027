export default function EmptyStack({type, topic}) {
    
    let firstSentence, secondSentence;
    switch(type) {
        case "chaine-ephemere":
            firstSentence = "";
        break;

        case "cities":
            let voyelles = ("aeiouAEIOU"); 
            if (voyelles.indexOf(topic[0]) !== -1) {
                firstSentence = "Aucun article n'est disponible pour la ville d'";
            } else {
                firstSentence = "Aucun article n'est disponible pour la ville de ";
            }
            secondSentence = "Essayez avec une autre ville !"
        break;

        case "searched":
            firstSentence = "Pas de résultat pour ";
            secondSentence = "Essayez avec d'autres mots clés";
        break;
    }

    return (
        <div className="empty-a-container">
            <h1>
                {firstSentence}{topic}
            </h1>
            <p>
                {secondSentence}
            </p>
        </div>
    )
}