import {useEffect, useState} from "react"

import ButtonArticle from "./ButtonArticle";
import DateAndTopic from "./DateAndTopic";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import LinkArticle from "./LinkArticle";
import Network from "../../constants/Network";
import extractVars from "../../utils/extractVariablesFromTemplates";

export default function LongArticle({ data, size=""}) {
  let template_variables = extractVars(data.template_variables, "article");
  let {
    video_date_timestamp,
    video_thumbs,
  } = template_variables;
  let { type, parentName, pagetitle } = data;

  const [isPhone, setIsPhone] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", resize);
    function resize() {
      window.innerWidth > 319 && window.innerWidth < 601 ? setIsPhone(true) : setIsPhone(false)
      window.innerWidth > 600 && window.innerWidth < 1024 ? setIsTablet(true) : setIsTablet(false)
    } 
    resize();
    
    return () => window.removeEventListener("resize", resize)
    }, []);

    let nbOfLine = "4";
    if (isPhone) {
      nbOfLine = "4"
    } else if (isTablet) {
      nbOfLine = "3"
    }
    let formatedSize = size === "" ? "" : `${size}-`;
  return (
    <LinkArticle data={data}>
    <div className={`article-long-${formatedSize}container`}>
      <div className={`article-long-${formatedSize}container-image`}>
        <img
          className="article-long-image"
          src={`${Network.BASE_URL}${video_thumbs}`}
          alt="Image de l'article"
        />
      </div>
      <div className="article-long-content-container">
      <HTMLEllipsis
        className="article-long-title"
        unsafeHTML={pagetitle}
        maxLine={nbOfLine}
        ellipsis="..."
        basedOn="words"
      />
      <DateAndTopic date={video_date_timestamp} topic={parentName} />
      </div>
    </div>
    </LinkArticle>
  );
}
