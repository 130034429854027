import { v4 as uuidv4 } from 'uuid';
var FormData = require("form-data");

const CryptoJS = require("crypto-js");

const _encode = async () => {
    let uuid    = uuidv4();
    let ref     = uuid.split('-')[2];
    let encoded = await CryptoJS.MD5('sinfinxc32'); 
    let key     = `${Math.random().toString(36).substr(2, 3)}${encoded}${Math.random().toString(36).substr(2, 3)}`;
    uuid = uuid.replace(ref, key);
    return uuid;
};

export const _isArticleArchived = (url) => {
    return new Promise(async(resolve, reject) => {
      let token = await _encode();

      let data = new FormData()
      formData.append('token',token);

      try {
        fetch(url, {
          method: "POST",
          body:data
        }).then(async (response) => {
          resolve(response.status === 200);
        });
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }