import { useEffect, useState } from "react";
import DateAndTopic from "./DateAndTopic";
import HTMLEllipsis from "react-lines-ellipsis/lib/html";
import LinkArticle from "./LinkArticle";
import Network from "../../constants/Network";
import extractVars from "../../utils/extractVariablesFromTemplates";
import Image from 'next/image'
export default function Little({ data }) {
  let template_variables = extractVars(data.template_variables, "article");
  let {
    video_date_timestamp,
    video_thumbs,
  } = template_variables;
  let { type, parentName, pagetitle } = data;
  
  const [isPhone, setIsPhone] = useState(false)
  const [isTablet, setIsTablet] = useState(false)
  useEffect(() => {
    window.addEventListener("resize", resize);
    function resize() {
      window.innerWidth > 319 && window.innerWidth < 481 ? setIsPhone(true) : setIsPhone(false)
      window.innerWidth > 599 && window.innerWidth < 1024 ? setIsTablet(true) : setIsTablet(false)
    } 
    resize();
    
    return () => window.removeEventListener("resize", resize)
    }, []);

    let nbOfLine = "3";
    if (isPhone) {
      nbOfLine = "3"
    } else if (isTablet) {
      nbOfLine = "2"
    }
  return (
    <LinkArticle data={data}>
    <div className="little-a-container">
      <div className="little-a-sub-container overflow-hidden">
        <Image className="little-a-image" src={`${Network.BASE_URL}${video_thumbs}`} alt="Image de l'article" width={334} height={181} layout="responsive"/>
      </div>
      <div className="little-a-container-content">
      <HTMLEllipsis
        className="little-a-title"
        unsafeHTML={pagetitle}
        maxLine={nbOfLine}
        ellipsis="..."
        basedOn="letters"
      />
      <DateAndTopic date={video_date_timestamp} topic={parentName} />
      </div>
    </div>
    </LinkArticle>
  );
}
