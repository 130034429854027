import ActualiteLogo from "../../public/images/ico/ico-actualite.svg";
import { Context } from "../../utils/store";
import Icon from "../Icon";
import { useContext } from "react";

export default function ShowsAndNews({containerStyle}) {
  const context = useContext(Context);
  const {activePage, liveData} = context;
  return (
    <Context.Provider
      value={{
        ...context
      }}
    >
    <div className={containerStyle}>
      <div className="sandn-subContainer rounded overflow-hidden">
        <div className="timebar"/>
      {liveData.items.map((e, i) => {
        let id = e.id;
        let content = e.title;
        if (id > 4) {
          if (id === 5) {
            return (
              <div key={i} className="sandn-container-title">
                <Icon icon="icon-ico-programmes" className="sandn-ico-programmes"/>
                <p className="sandn-content">
                  {content}
                </p>
              </div>
            );
          } else {
            let regexp = /:(.+)/;
            let content_split = content.split(regexp);

            let showtime = content_split[0]?.trim();
            let showcontent = content_split[1]?.trim();

            let regexp_showtime = /[\/&,\\ ]/gm;
            showtime = showtime.replace(regexp_showtime, "\n");
            /*return (
              <div className={`sandn-item-shows ${i === 5 ? "pt-8" : ""}`} key={i}>
                <div className="sandn-item-hour relative">
                  <div className="absolute w-8 h-full bg-light-gray mx-auto left-0 right-0"/>
                   <p>{`${showtime}`}</p>
                </div>
                <p className="sandn-item-text">{showcontent}</p>
              </div>
            );*/
            return(
              <div className={`sandn-item-shows ${i === 5 ? "mt-20 mb-10" : "mt-10 mb-10"}`}>
                <div className="block text-dark-blue w-full font-bold">{showtime}</div>
                <div className="block">{showcontent}</div>
              </div>
            )
          }
        }
      })}
      </div>
      {
        activePage !== "direct" && 
        <div className="sandn-subContainer rounded overflow-hidden">
      {liveData.items.map((e, i) => {
        let id = e.id;
        let content = e.title;
        // Between 5 and 8
        if (id === 1) {
          return (
            <div key={i} className="sandn-container-title">
              <Icon icon="icon-ico-actualite" className="sandn-ico-programmes"/>
              <p className="sandn-content">
                {content}
              </p>
            </div>
          );
        } else if (id < 5) {
          return (
            <div className={`sandn-item-news ${i !== 1 ? 'border-t-1' : ''}`} key={i}>
              <li className="text">
              {content}
              </li>
            </div>
          );
        }
      })}
      </div>
      }
    </div>
    </Context.Provider>
  );
}
