var FormData = require("form-data");
import fs from "fs";
import setGetData from "./data";
import { v4 as uuidv4 } from 'uuid';

const CryptoJS = require("crypto-js");

const _encode = async () => {
    let uuid    = uuidv4();
    let ref     = uuid.split('-')[2];
    let encoded = await CryptoJS.MD5('sinfinxc32'); 
    let key     = `${Math.random().toString(36).substr(2, 3)}${encoded}${Math.random().toString(36).substr(2, 3)}`;
    uuid = uuid.replace(ref, key);
    return uuid;
};

export default async function initOrGetData(uri, method, body,json=true) {
    let formData;
    if(method=='GET'){
        formData=null;
    } else{
        formData= new FormData();
        let token = await _encode();
        formData.append('token',token);
        if (body != null) {
            body.map((e, i) => {
                formData.append(e.name, e.value);
            });
        } 
    }      



    const res = await fetch(`${uri}`, {
        method: method,
        body: formData,
    });

    
    const response = await res.json();

    return response;
};
